import React, {useState} from "react"
import {useQueryClient} from "@tanstack/react-query"
import classNames from "classnames"

import "features/offer/OfferLeaderboardPage.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import PageContainer from "components/PageContainer/PageContainer"
import PageHeader from "components/PageHeader/PageHeader"
import ConnectWalletModal from "features/connect/ConnectWalletModal"
import OfferLeaderboardTable from "features/offer/OfferLeaderboardTable"
import network from "utils/network"
import {useQueryWithErrorHandling, useMutationWithErrorHandling} from "utils/query"

const offerLeaderboardRequest = () => network.get("/offer/leaderboard")

const walletAuthRequest = () => network.get("/wallet/auth")

const walletConnectRequest = walletData =>
  network.post("/wallet/connect", walletData)

const OfferLeaderboardPage = () => {
  const [modalVisible, setModalVisible] = useState(false)
  const [authQueryEnabled, setAuthQueryEnabled] = useState(false)
  const queryClient = useQueryClient()

  const {data, isLoading, isError} = useQueryWithErrorHandling({
    queryKey: ["offer", "leaderboard"],
    queryFn: offerLeaderboardRequest,
  })

  const invalidateOfferLeaderboardQuery = () => {
    queryClient.invalidateQueries({queryKey: ["offer", "leaderboard"]})
  }

  const walletAuth = useQueryWithErrorHandling({
    queryKey: ["wallet", "auth"],
    queryFn: walletAuthRequest,
    enabled: authQueryEnabled,
    onSuccess: () => setModalVisible(true),
    onSettled: () => setAuthQueryEnabled(false),
  })

  const walletConnect = useMutationWithErrorHandling({
    mutationFn: walletConnectRequest,
    onSuccess: invalidateOfferLeaderboardQuery,
  })

  const handleConnectWallet = data => walletConnect.mutate(data)

  if (isLoading) {
    return (
      <PageContainer className="offer-leaderboard-page__loading">
        <LoadingSpinner />
      </PageContainer>
    )
  }

  if (isError) {
    return (
      <PageContainer className="offer-leaderboard-page__error">
        <p>Oops, something went wrong!</p>
      </PageContainer>
    )
  }

  const {header, title, accountRank, accountHm} = data.body

  return (
    <PageContainer>
      <PageHeader
        {...header}
        onWalletConnect={invalidateOfferLeaderboardQuery}
      />
      <div className="offer-leaderboard-page__container">
        <div className={classNames(
          "offer-leaderboard-page__header",
          {"offer-leaderboard-page__header--public": !accountHm},
        )}>
          <h1 className="offer-leaderboard-page__title">{title}</h1>
          {accountRank ? (
            <div className="offer-leaderboard-page__summary">
              <div className="offer-leaderboard-page__summary-card">
                <div className="offer-leaderboard-page__summary-card-title">
                  Rank
                </div>
                <div className="offer-leaderboard-page__summary-card-figure">
                  {accountRank}
                </div>
              </div>
              <div className="offer-leaderboard-page__summary-card">
                <div className="offer-leaderboard-page__summary-card-title">
                  $HM
                </div>
                <div className="offer-leaderboard-page__summary-card-figure">
                  {accountHm}
                </div>
              </div>
            </div>
          ) : (
            <>
              <Button
                className="offer-leaderboard-page__button"
                onClick={() => setAuthQueryEnabled(true)}
                disabled={authQueryEnabled}
                loading={authQueryEnabled}
                isSecondary>
                Connect wallet
              </Button>
              <ConnectWalletModal
                nonce={walletAuth.data?.body?.nonce}
                onDismiss={() => setModalVisible(false)}
                isConnectingWallet={walletConnect.isPending}
                onWalletConnect={handleConnectWallet}
                visible={modalVisible}
              />
            </>
          )}
        </div>
        <OfferLeaderboardTable />
      </div>
    </PageContainer>
  )
}

export default OfferLeaderboardPage
