import React from "react"
import {useInfiniteQuery} from "@tanstack/react-query"
import {Fire, Parachute, Wallet} from "@phosphor-icons/react"
import classNames from "classnames"

import "features/offer/OfferLeaderboardTable.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import TextWithIcon from "components/TextWithIcon/TextWithIcon"
import network from "utils/network"

const TableHeader = () => (
  <div
    key="table-header"
    className="offer-leaderboard-table__header">
    <div className={classNames(
      "offer-leaderboard-table__rank-col",
      "offer-leaderboard-table__header-col",
    )}>
      Rank
    </div>
    <div className={classNames(
      "offer-leaderboard-table__account-col",
      "offer-leaderboard-table__header-col",
    )}>
      Account
    </div>
    <div className={classNames(
      "offer-leaderboard-table__amount-col",
      "offer-leaderboard-table__header-col",
    )}>
      $HM
    </div>
  </div>
)

const TableRows = ({rows}) => (
  <>
    {rows.map(({
      rank,
      amountAlloc,
      amountBurned,
      amountDropped,
      isUser,
      avatarLayers,
      walletCount,
      dropWallet,
    }, idx) => (
      <div
        key={`row-${idx}`}
        className={classNames(
          "offer-leaderboard-table__row",
          {
            "offer-leaderboard-table__row--even": idx%2==0,
            "offer-leaderboard-table__row--hl": isUser,
            "offer-leaderboard-table__row--burned": amountBurned,
          },
        )}>
        <div className={classNames(
          "offer-leaderboard-table__rank-col",
          "offer-leaderboard-table__rank",
        )}>{rank}</div>
        <div className={classNames(
          "offer-leaderboard-table__account-col",
          "offer-leaderboard-table__account",
        )}>
          <div className="offer-leaderboard-table__avatar-container">
            {avatarLayers.map((imgUrl, idx) => (
              <img
                key={`layer-${idx}`}
                className="offer-leaderboard-table__avatar-layer"
                src={imgUrl} />
            ))}
          </div>
          <div className="offer-leaderboard-table__wallet">[{dropWallet}]</div>
          {walletCount > 1 && (
            <>
              <div className={classNames(
                "offer-leaderboard-table__extra-wallets",
                {"offer-leaderboard-table__extra-wallets--hl": isUser},
              )}>
                +{walletCount - 1}
              </div>
              <Wallet className="offer-leaderboard-table__wallet-icon" />
            </>
          )}
        </div>
        <div className={classNames(
          "offer-leaderboard-table__amount-col",
          {
            "offer-leaderboard-table__amount": !amountBurned,
            "offer-leaderboard-table__amount-burned": amountBurned,
            "offer-leaderboard-table__amount--hl": isUser,
          },
        )}>
          {amountBurned ? (
            <>
              <TextWithIcon
                textClassName="offer-leaderboard-table__amount-burned-text"
                text={amountDropped}
                iconLeft={<Parachute />}
              />
              /
              <TextWithIcon
                textClassName="offer-leaderboard-table__amount-burned-text"
                text={amountBurned}
                iconLeft={<Fire />}
              />
            </>
          ) : amountAlloc}
        </div>
      </div>
    ))}
  </>
)

const offerLeaderboardEntriesRequest = ({pageParam}) =>
  network.get(`/offer/leaderboard/entries?lastKey=${pageParam}`)

// this basically flattens pages and calculates rank for each row
const getRows = data => {
  const rows = (data?.pages || [])
    .flatMap(p => p?.body?.entries)
    .reduce(
      (acc, val) => {
        const newRank = acc.amountAlloc === val.amountAlloc ? acc.rank : acc.rank + 1
        return {
          rank: newRank,
          amountAlloc: val.amountAlloc,
          result: [...acc.result, {...val, rank: newRank}],
        }
      },
      {rank: 0, amountAlloc: 0, result: []})

  return rows.result
}

const OfferLeaderboardTable = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isError,
  } = useInfiniteQuery({
    queryKey: ["offer", "leaderboard", "entries"],
    queryFn: offerLeaderboardEntriesRequest,
    initialPageParam: "",
    getNextPageParam: (lastPage) => lastPage?.body?.lastKey,
  })

  return (
    <div className="offer-leaderboard-table">
      <TableHeader />
      {isPending ? (
        <div className="offer-leaderboard-table__loading">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="offer-leaderboard-table__error">
          <p>Oops, something went wrong!</p>
        </div>
      ) : (
        <>
          <TableRows rows={getRows(data)} />
          {hasNextPage && (
            <div className="offer-leaderboard-table__load-more">
              <Button
                onClick={fetchNextPage}
                loading={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                isSecondary>
                {isFetchingNextPage ? "Loading more..." : "Load more"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default OfferLeaderboardTable
